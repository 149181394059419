<template>
	<div :class="['preview-main', {show: showDrawer}]">
		<div style="flex: 1; display: flex; flex-direction: column;">
			<div style="padding: 10px 0; background-color: #fff; text-align: center;">
				<el-select size="mini" style="width: 100px;" v-model="device.mode" placeholder="请选择设备尺寸">
					<el-option v-for="item in devices" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-input-number size="mini" style="margin-left: 10px; display: inline-block; width: 100px;" placeholder="宽度" controls-position="right" v-model="device.width"></el-input-number>
				<el-input-number size="mini" style="margin-left: 10px; display: inline-block; width: 100px;" placeholder="高度" controls-position="right" v-model="device.height"></el-input-number>
				<el-select size="mini" style="margin-left: 10px; width: 80px;" v-model="device.size" placeholder="请选择">
					<el-option v-for="item in sizes" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
			</div>

			<div style="flex: 1; display: flex; position: relative;">
				<div style="flex: 1; position: relative;">
					<div class="iframe-wrapper" :style="{width: device.width + 'px', height: device.height + 'px'}">
						<iframe :src="'http://192.168.31.232:8081/#/platforms/h5/pages?id=' + id" frameborder="0" style="background-color:transparent;width: 100%; height: 100%;"></iframe>
					</div>
				</div>
				<div style="width: 240px; padding-top: 5%; text-align: center; position: relative;">
					<vue-qr :margin="10" :size="200" :text="'http://192.168.31.232:8081/#/platforms/h5/pages?id=' + id"></vue-qr>
				</div>
			</div>
		</div>
		<div class="scrollbar-wrapper" style="width: 500px; padding: 20px 10px 80px; background-color: #fff; border-left: 1px solid #ccc;">
			<el-scrollbar style="height: 100%;">
				<div style="padding-right: 10px;">
					<el-descriptions size="mini" direction="vertical" :column="1">
						<el-descriptions-item label="主图">
							<upload-image btn-text="更换主图" :width="100" :height="100" :url.sync="projectData.cover" :params="{type: 'article_thumb'}" library crop></upload-image>
						</el-descriptions-item>
						<el-descriptions-item label="名称">
							<el-input size="mini" placeholder="请输入作品的名称。仅用于后台显示" v-model="projectData.name"></el-input>
						</el-descriptions-item>
						<el-descriptions-item label="标题">
							<el-input size="mini" placeholder="请输入作品的标题" v-model="projectData.title"></el-input>
						</el-descriptions-item>
						<el-descriptions-item label="描述">
							<el-input size="mini" placeholder="请输入作品的简单描述" type="textarea" :rows="3" v-model="projectData.description"></el-input>
						</el-descriptions-item>
						<el-descriptions-item label="访问状态">
							<el-select size="mini" v-model="projectData.status">
								<el-option label="允许访问" :value="1"></el-option>
								<el-option label="不允许访问" :value="0"></el-option>
							</el-select>
						</el-descriptions-item>
						<el-descriptions-item label="引导页" v-if="projectData.pages.length > 1">
							<el-checkbox size="mini" v-model="projectData.loading" :false-label="0" :true-label="1" border>设置第一页为加载引导页</el-checkbox>
						</el-descriptions-item>
					</el-descriptions>
					<el-collapse>
						<el-collapse-item title="背景音乐" name="1">
							<el-descriptions size="mini" :column="1">
								<el-descriptions-item label="引导页">
									<el-checkbox size="mini" v-model="projectData.bgm.first" :false-label="0" :true-label="1" border>允许在第一页播放</el-checkbox>
								</el-descriptions-item>
								<el-descriptions-item label="大小">
									<el-input-number controls-position="right" placeholder="请输入大小" size="mini" v-model="projectData.bgm.size" :min="1"></el-input-number>
								</el-descriptions-item>
								<el-descriptions-item label="位置(top)">
									<el-input-number controls-position="right" placeholder="请输入大小" size="mini" v-model="projectData.bgm.top" :min="1"></el-input-number>
								</el-descriptions-item>
								<el-descriptions-item label="位置(right)">
									<el-input-number controls-position="right" placeholder="请输入大小" size="mini" v-model="projectData.bgm.right" :min="1"></el-input-number>
								</el-descriptions-item>
							</el-descriptions>
							<el-descriptions size="mini" :column="1" direction="vertical">
								<el-descriptions-item label="音乐资源">
									<el-input placeholder="请输入内容" size="mini" v-model="projectData.bgm.resource" clearable>
										<el-upload
											slot="prepend"
											:show-file-list="false"
											:data="{type: 'article_thumb'}"
											:on-success="(r, f, l) => { !r.code ? (projectData.bgm.resource = r.result.url) : $message.error(r.msg)}"
											:action="$config.APIHOST+'uploads'">
											<el-button icon="el-icon-upload"></el-button>
										</el-upload>
										<el-button slot="append" icon="el-icon-video-play" @click="$utils.openWindow(projectData.bgm.resource)"></el-button>
									</el-input>
								</el-descriptions-item>
								<el-descriptions-item label="控制图标">
									<upload-image :width="60" :height="60" :url.sync="projectData.bgm.icon" :params="{type: 'article_thumb'}" library crop></upload-image>
								</el-descriptions-item>
							</el-descriptions>
						</el-collapse-item>
						<el-collapse-item title="翻页效果" name="2" v-if="projectData.pages.length > (projectData.loading?2:1)">
							<el-descriptions size="mini" :column="1">
								<el-descriptions-item label="模式">
									<el-select size="mini" v-model="projectData.flip.effect">
										<el-option label="位移" value="slide"></el-option>
										<el-option label="淡入" value="fade"></el-option>
										<el-option label="卡片" value="cards"></el-option>
										<el-option label="翻转" value="flip"></el-option>
										<el-option label="方块" value="cube"></el-option>
										<el-option label="创意" value="creative"></el-option>
										<el-option label="3D流" value="coverflow"></el-option>
									</el-select>
								</el-descriptions-item>
								<el-descriptions-item label="滑动方向">
									<el-switch active-text="垂直" inactive-text="水平" active-value="vertical" inactive-value="horizontal" v-model="projectData.flip.direction"></el-switch>
								</el-descriptions-item>
								<el-descriptions-item label="淡入淡出" v-if="projectData.flip.effect == 'fade'">
									<el-switch active-text="开启" inactive-text="关闭" :active-value="true" :inactive-value="false" v-model="projectData.flip.fadeEffect.crossFade"></el-switch>
								</el-descriptions-item>
								<el-descriptions-item label="卡片阴影" v-if="projectData.flip.effect == 'cards'">
									<el-switch active-text="开启" inactive-text="关闭" :active-value="true" :inactive-value="false" v-model="projectData.flip.cardsEffect.slideShadows"></el-switch>
								</el-descriptions-item>
								<template v-if="projectData.flip.effect == 'flip'">
									<el-descriptions-item label="卡片阴影">
										<el-switch active-text="开启" inactive-text="关闭" :active-value="true" :inactive-value="false" v-model="projectData.flip.flipEffect.slideShadows"></el-switch>
									</el-descriptions-item>
									<el-descriptions-item label="限制转角">
										<el-switch active-text="开启" inactive-text="关闭" :active-value="true" :inactive-value="false" v-model="projectData.flip.flipEffect.limitRotation"></el-switch>
									</el-descriptions-item>
								</template>
								<template v-if="projectData.flip.effect == 'cube'">
									<el-descriptions-item label="卡片阴影">
										<el-switch active-text="开启" inactive-text="关闭" :active-value="true" :inactive-value="false" v-model="projectData.flip.cubeEffect.slideShadows"></el-switch>
									</el-descriptions-item>
									<el-descriptions-item label="限制转角">
										<el-switch active-text="开启" inactive-text="关闭" :active-value="true" :inactive-value="false" v-model="projectData.flip.cubeEffect.shadow"></el-switch>
									</el-descriptions-item>
									<el-descriptions-item label="投影距离">
										<el-input-number size="mini" v-model="projectData.flip.cubeEffect.shadowOffset" :min="0"></el-input-number>
									</el-descriptions-item>
									<el-descriptions-item label="投影缩放">
										<el-input-number size="mini" v-model="projectData.flip.cubeEffect.shadowScale" :min="0.01" :max="1" :step="0.01" :precision="2"></el-input-number>
									</el-descriptions-item>
								</template>
								<template v-if="projectData.flip.effect == 'coverflow'">
									<el-descriptions-item label="卡片阴影">
										<el-switch active-text="开启" inactive-text="关闭" :active-value="true" :inactive-value="false" v-model="projectData.flip.coverflowEffect.slideShadows"></el-switch>
									</el-descriptions-item>
									<el-descriptions-item label="旋转角度">
										<el-input-number size="mini" v-model="projectData.flip.coverflowEffect.rotate" :min="0" :step="1"></el-input-number>
									</el-descriptions-item>
									<el-descriptions-item label="拉伸比例">
										<el-input-number size="mini" v-model="projectData.flip.coverflowEffect.stretch" :min="0" :step="1"></el-input-number>
									</el-descriptions-item>
									<el-descriptions-item label="位置深度">
										<el-input-number size="mini" v-model="projectData.flip.coverflowEffect.depth" :min="0" :step="1"></el-input-number>
									</el-descriptions-item>
									<el-descriptions-item label="效果倍率">
										<el-input-number size="mini" v-model="projectData.flip.coverflowEffect.modifier" :min="0" :step="1"></el-input-number>
									</el-descriptions-item>
								</template>
							</el-descriptions>
							<el-descriptions size="mini" direction="vertical" :column="1" v-if="projectData.flip.effect == 'creative'">
								<el-descriptions-item label="左or上变形">
									<el-descriptions size="mini" :column="1">
										<el-descriptions-item label="位移[Z,X,Y]">
											<el-input size="mini" class="input-wr" v-model="projectData.flip.creativeEffect.prev.translate[0]"></el-input>
											<el-input size="mini" class="input-wr" v-model="projectData.flip.creativeEffect.prev.translate[1]"></el-input>
											<el-input size="mini" class="input-wr" v-model="projectData.flip.creativeEffect.prev.translate[2]"></el-input>
										</el-descriptions-item>
										<el-descriptions-item label="旋转[Z,X,Y]">
											<el-input-number size="mini" class="input-wr" v-model="projectData.flip.creativeEffect.prev.rotate[0]"></el-input-number>
											<el-input-number size="mini" class="input-wr" v-model="projectData.flip.creativeEffect.prev.rotate[1]"></el-input-number>
											<el-input-number size="mini" class="input-wr" v-model="projectData.flip.creativeEffect.prev.rotate[2]"></el-input-number>
										</el-descriptions-item>
										<el-descriptions-item label="透明度">
											<el-input-number size="mini" v-model="projectData.flip.creativeEffect.prev.opacity" :min="0" :step="1"></el-input-number>
										</el-descriptions-item>
										<el-descriptions-item label="缩放">
											<el-input-number size="mini" v-model="projectData.flip.creativeEffect.prev.scale" :min="0" :step="1"></el-input-number>
										</el-descriptions-item>
										<el-descriptions-item label="阴影">
											<el-switch active-text="开启" inactive-text="关闭" :active-value="true" :inactive-value="false" v-model="projectData.flip.creativeEffect.prev.shadow"></el-switch>
										</el-descriptions-item>
									</el-descriptions>
								</el-descriptions-item>
								<el-descriptions-item label="右or下变形">
									<el-descriptions size="mini" :column="1">
										<el-descriptions-item label="位移[Z,X,Y]">
											<el-input size="mini" class="input-wr" v-model="projectData.flip.creativeEffect.next.translate[0]"></el-input>
											<el-input size="mini" class="input-wr" v-model="projectData.flip.creativeEffect.next.translate[1]"></el-input>
											<el-input size="mini" class="input-wr" v-model="projectData.flip.creativeEffect.next.translate[2]"></el-input>
										</el-descriptions-item>
										<el-descriptions-item label="旋转[Z,X,Y]">
											<el-input-number size="mini" class="input-wr" v-model="projectData.flip.creativeEffect.next.rotate[0]"></el-input-number>
											<el-input-number size="mini" class="input-wr" v-model="projectData.flip.creativeEffect.next.rotate[1]"></el-input-number>
											<el-input-number size="mini" class="input-wr" v-model="projectData.flip.creativeEffect.next.rotate[2]"></el-input-number>
										</el-descriptions-item>
										<el-descriptions-item label="透明度">
											<el-input-number size="mini" v-model="projectData.flip.creativeEffect.next.opacity" :min="0" :step="1"></el-input-number>
										</el-descriptions-item>
										<el-descriptions-item label="缩放">
											<el-input-number size="mini" v-model="projectData.flip.creativeEffect.next.scale" :min="0" :step="1"></el-input-number>
										</el-descriptions-item>
										<el-descriptions-item label="阴影">
											<el-switch active-text="开启" inactive-text="关闭" :active-value="true" :inactive-value="false" v-model="projectData.flip.creativeEffect.next.shadow"></el-switch>
										</el-descriptions-item>
									</el-descriptions>
								</el-descriptions-item>
							</el-descriptions>
						</el-collapse-item>
						<el-collapse-item title="指示器" name="3" v-if="projectData.pages.length > (projectData.loading?2:1)">
							<el-descriptions size="mini" :column="1">
								<el-descriptions-item label="模式">
									<el-select size="mini" v-model="projectData.indicator.mode">
										<el-option label="无" value="none"></el-option>
										<el-option label="点型" value="dot"></el-option>
										<el-option label="线型" value="line"></el-option>
										<el-option label="图标" value="icon"></el-option>
										<el-option label="页码" value="number"></el-option>
										<el-option label="进度条" value="progress"></el-option>
									</el-select>
								</el-descriptions-item>
								<el-descriptions-item label="大小">
									<el-input-number controls-position="right" placeholder="请输入大小" size="mini" v-model="projectData.indicator.size" :min="1"></el-input-number>
								</el-descriptions-item>
								<el-descriptions-item label="激活颜色">
									<el-color-picker size="mini" v-model="projectData.indicator.inactiveColor"></el-color-picker>
								</el-descriptions-item>
								<el-descriptions-item label="非激活颜色">
									<el-color-picker size="mini" v-model="projectData.indicator.activeColor"></el-color-picker>
								</el-descriptions-item>
							</el-descriptions>
						</el-collapse-item>
					</el-collapse>
				</div>
			</el-scrollbar>
			<div style="margin-top: 20px;">
				<el-button size="medium" type="primary" @click="$emit('publish')">保存预览</el-button>
				<el-button size="medium" @click="$emit('closePreview')">关闭预览</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import vueQr from 'vue-qr';
	import { mapState } from 'vuex';
	import uploadImage from '@/components/upload-image';

	export default {
		components: {
			vueQr,
			uploadImage
		},
		props: {
			id: {
				type: Number,
				require: true
			},
			showDrawer: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			...mapState({
				projectData: state => state.editor.projectData
			})
		},
		data() {
			return {
				device: {
					mode: 'custom',
					width: this.$config.canvasH5Width,
					height: this.$config.canvasH5Height,
					size: 100
				},
				devices: [
					{
						label: '自定义',
						value: 'custom'
					}, {
						label: 'Iphone 12',
						value: 'iphone_12'
					}, 
				],
				sizes: [
					{
						label: '50%',
						value: 50
					}, {
						label: '75%',
						value: 75
					}, {
						label: '100%',
						value: 100
					}, {
						label: '120%',
						value: 120
					}, {
						label: '150%',
						value: 150
					}, {
						label: '200%',
						value: 200
					}
				]
			}
		}
	}
</script>

<style lang="scss" scoped>
	.preview-main {
		display: none;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 999;
		background-color: rgba(0, 0, 0, .8);
	}
	.preview-main.show {
		display: flex;
	}
	.iframe-wrapper{
		position: absolute;
		left: 50%;
		top: 50%;
		background: #f1f1f1;
		transform: translate(-50%, -50%);
	}
	.input-wr {
		width: 100px;
		margin-right: 10px;
	}
</style>
