<template>
	<div>
		<div class="inline-block cropper-res-img" :style="{width: width+'px', height: height+'px'}" v-if="width && height">
			<template v-if="btnText">
				<img :src="url" v-if="url">
				<i v-if="!url" :class="library?'el-icon-picture':'el-icon-upload'" :style="{fontSize: (width/3)+'px'}"></i>
				<p class="cropper-res-img-title" v-if="library" @click="libraryVisible = true">{{url?btnText:'选择图片'}}</p>
				<el-upload accept="image/gif, image/jpeg, image/png, image/bmp" :action="$config.APIHOST+'uploads'" :data="params" :before-upload="beforeUpload" :on-success="onSuccess" :show-file-list="false" v-else>
					<p class="cropper-res-img-title">{{url?btnText:'点击上传'}}</p>
				</el-upload>
			</template>
			<template v-else>
				<div v-if="library" @click="libraryVisible = true">
					<img :src="url" v-if="url">
					<i v-if="!url" class="el-icon-picture" :style="{fontSize: (width/3)+'px'}"></i>
					<div v-if="!url && width >= 240" class="el-upload__text">点击从素材库选择图片</div>
				</div>
				<el-upload accept="image/gif, image/jpeg, image/png, image/bmp" :action="$config.APIHOST+'uploads'" :data="params" :before-upload="beforeUpload" :on-success="onSuccess" :show-file-list="false" v-else>
					<img :src="url" v-if="url">
					<i v-if="!url" class="el-icon-upload" :style="{fontSize: (width/3)+'px'}"></i>
					<div v-if="!url && width >= 240" class="el-upload__text">将封面图片拖到此处，或<em>点击上传</em><br>只能上传jpg/png文件，且不超过500kb</div>
				</el-upload>
			</template>
		</div>
		<el-input placeholder="请输入资源链接" size="mini" v-model="url" @change="(v) => { $emit('update:url', v) }" v-else clearable>
			<template slot="prepend">
				<el-button icon="el-icon-picture" v-if="library" @click="libraryVisible = true"></el-button>
				<el-upload :action="$config.APIHOST+'uploads'" :data="params" :before-upload="beforeUpload" :on-success="onSuccess" :show-file-list="false" v-else>
					<el-button icon="el-icon-upload"></el-button>
				</el-upload>
			</template>
			<el-button slot="append" icon="el-icon-view" @click="$utils.openWindow(url)"></el-button>
		</el-input>

		<el-dialog title="图片素材库" :visible.sync="libraryVisible" append-to-body>
			<el-scrollbar class="image-list-wrapper scroll-wrapper" v-if="imageList.length">
				<div class="image-item" v-for="(item, index) in imageList" :key="index" @click="handleImageClick(item.url)">
					<img :src="item.url" alt="">
				</div>
			</el-scrollbar>
			<div class="padding60 text-center gray" v-else>暂无数据</div>
			<template slot="footer" class="dialog-footer">
				<el-upload slot="prepend" :action="$config.APIHOST+'uploads'" :data="params" :before-upload="beforeUpload" :on-success="onSuccess" :show-file-list="false">
					<el-button icon="el-icon-upload">点击上传</el-button>
				</el-upload>
			</template>
		</el-dialog>

		<el-dialog title="图片裁剪" :visible.sync="cropVisible" append-to-body>
			<div style="height: 400px;">
				<vue-cropper ref="cropper" outputType="png" :img="originImage" :info="false" :full="false" :fixed="true" :fixedNumber="[width/height, 1]" :autoCrop="true" :fixedBox="false" :centerBox="false" :autoCropWidth="width" :autoCropHeight="height"></vue-cropper>
			</div>
			<slot name="tips">
				<div style="margin-top: 15px; line-height: 1.5;">
					<p>1、滚动鼠标可以放大缩小原图；</p>
					<p>2、阴影区域按住鼠标右键可以拖拽原图；</p>
					<p>3、裁剪区域按住鼠标右键可以拖拽裁剪指定区域；</p>
				</div>
			</slot>
			<template slot="footer" class="dialog-footer">
				<el-button type="primary" @click="updateImage" size="small">裁剪上传</el-button>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import { VueCropper } from 'vue-cropper';

	export default {
		components: {
			VueCropper
		},
		props: {
			library: {
				type: Boolean,
				default: false
			},
			crop: {
				type: Boolean,
				default: false
			},
			params: {
				require: true,
				type: Object
			},
			width: Number,
			height: Number,
			btnText: String,
			url: String,
		},
		data() {
			return {
				loading: false,
				libraryVisible: false,
				cropVisible: false,
				originImage: '',
				imageList: [
					{url: 'http://seopic.699pic.com/photo/50051/4111.jpg_wh1200.jpg'},
					{url: 'http://aimg.dajing.ren/article/20230103/akw62kAM91VTSwBFbkJZh71nmsJZjGG0W3UQF0UV.png'}
				]
			}
		},
		methods: {
			updateImage () {
				this.loading = true;
				this.$refs.cropper.getCropBlob((data) => {
					let file = blobToFile(data)
					let params = new FormData()
					params.append('file', file);
					this.$API.uploadCommonImage(params).then(res => {
						this.loading = false;
						this.cropVisible = false;
						this.$emit('update:url', res.body.url);
					}).catch(() => {
						this.loading = false;
					})
				})
			},
			beforeUpload (file) {
				if (!this.crop) return true;
				if (file.size > 4 * 1024 * 1024) {
					this.$message.error('上传图片不能超过4M！');
					return;
				}
				var reader = new FileReader();
				let _this = this;
				reader.onload = (e) => {
					let data;
					if (typeof e.target.result === 'object') {
						// 把Array Buffer转化为blob 如果是base64不需要
						data = window.URL.createObjectURL(new Blob([e.target.result]));
					} else {
						data = e.target.result;
					}
					_this.originImage = data;
				}
				// 转化为base64
				reader.readAsDataURL(file);
				this.libraryVisible = false;
				this.cropVisible = true;
				// 转化为blob
				// reader.readAsArrayBuffer(file);
				return false;
			},
			onSuccess (r) {
				!r.code ? this.$emit('update:url', r.result.url) : this.$message.error(r.msg)
			},
			handleImageClick (url) {
				if (this.width && this.height && this.crop) {
					let img = new Image();
					img.src = url;
					if ((this.height/this.width) != (img.height/img.width)) {
						this.originImage = url;
						this.libraryVisible = false;
						this.cropVisible = true;
						return false;
					}
				}
				this.$emit('update:url', url);
				this.$emit('change', url);
				this.libraryVisible = false;
			}
		}
	}
	function blobToFile(blob, fileName){
		blob.lastModifiedDate = new Date();
		blob.name = fileName || new Date().getTime();
		return blob;
	}
</script>

<style scoped>
	.cropper-res-img {
		width: 100px;
		height: 100px;
		border: 1px dashed $primary;
		border-radius: 3px;
		margin-bottom: 18px;
		text-align: center;
		cursor: pointer;
		transition: all 0.28s;
		position: relative;
		background: #eee 50%/contain no-repeat;
		justify-content: center;
		align-items: center;
		display: flex;
	}
	.cropper-res-img:hover {
		color: $primary;
	}
	.cropper-res-img .cropper-res-img-title {
		width: 80px;
		color: #eee;
		padding: 6px 8px;
		border-radius: 4px;
		text-align: center;
		transform: translateX(-50%);
		background: rgba(0, 0, 0, 0.7);
		transition: all 0.28s;
		position: absolute;
		left: 50%;
		bottom: 10px;
		&:hover {
			background: rgba(0, 0, 0, 0.8);
			color: white;
		}
	}
	.cropper-res-img img {
		display: inline-block;
		max-width: 100%;
		max-height: 100%;
	}


	.image-list-wrapper {
		height: 48vh;
	}
	.image-list-wrapper .image-item {
		width: 120px;
		height: 120px;
		float: left;
		background: #eee 50%/contain no-repeat;
		cursor: pointer;
		justify-content:center;
		align-items:center;
		display: flex;
		transition: all 0.28s;
		margin: 5px;
	}
	.image-list-wrapper .image-item:hover {
		box-shadow: 0 0 16px 0 rgba(0,0,0,.16);
		transform: translate3d(0,-2px,0);
	}
	.image-list-wrapper .image-item img{
		display: inline-block;
		max-width: 100%;
		max-height: 100%;
	}
</style>
